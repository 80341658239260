<template>
    <div class="main-page">
        <div class="d-flex justify-space-between">
            <h3 class="second-text">Дата след. платежа</h3>
            <h3 class="second-text">Сумма</h3>
        </div>

                <div class="info-block-body pt-6">
                    <div class="pt-2">
                        <div
                            class="d-flex justify-space-between mt-2"
                            v-for="(schedule, i) in $root.incomingData.schedule"
                            :key="i"
                        >
                            <p :class="schedule.statusPayments == 3 && 'error-text'" class="main-text">
                                {{ schedule.dtInsert }}
                            </p>
                            <p :class="schedule.statusPayments == 3 && 'error-text'" class="main-text d-flex align-center">
<!--                                        <img v-if="schedule.statusPayments == 2 && $root.incomingData.theme == 'purple'" class="mr-1" src="@/assets/img/check-icon-purple.png" alt="">-->
                                <img v-if="schedule.statusPayments == 2" class="mr-1" src="@/assets/img/check-icon.svg" alt="">
                                {{ schedule.amountPayment | money }}
                            </p>
                        </div>
                    </div>
                </div>
            <!-- </v-expansion-panel>
        </v-expansion-panels> -->

<!--        <v-btn-->
<!--            v-if="$root.incomingData.theme === 'blue'"-->
<!--            :color="$root.incomingData.theme"-->
<!--            class="main-btn fixed-btn">Досрочное погашение-->
<!--        </v-btn>-->
    </div>
</template>

<script>
import money from '@/helpers/moneyFormat.js';

export default {
    filters: {
        money
    },
};
</script>

<style lang="scss" scoped>
.shedule {
    border-bottom: none !important;

    &.v-item--active {
        .header-value {
            font-weight: 700;
        }
    }
}

.shedule-header {
    padding: 0 !important;
}
</style>
