<template>
    <div >
        <div class="main-page">
            <p class="main-text">Осталось оплатить</p>
            <h2 class="money-title">{{ incomingData.remainsPay | money }}</h2>
            <p :class="$root.incomingData.isRepaidLoan ? 'mb-10' : ''" class="main-text">
                Сумма покупки: {{ incomingData.amountPurchase | money }}
            </p>
            <div
                v-if="!$root.incomingData.isRepaidLoan"
                :class="$root.incomingData.theme"
                class="br-12 mt-6 pa-4 text-center main-text block-text colorful-block"
            >
                {{ incomingData.theme === 'purple' ? 'Ближайший платеж' : 'Списание'}}
                {{incomingData.nextPay.date | dateWithoutYear}}:
                {{ incomingData.nextPay.amount | money }}
            </div>

            <div
                v-if="!$root.incomingData.isRepaidLoan"
                @click="$root.steps.setStep('ChoiceCard', settings, 'SinglePurchase')"
                class="pointer d-flex align-center mt-10"
            >
                <div class="circle__img mr-4">
                    <img v-if="$root.incomingData.theme === 'purple'"  src="@/assets/img/check-icon-purple.svg" alt="" />
                    <img v-else src="@/assets/img/pay.svg" alt="" />
                </div>

                <h4 class="main-title">{{ $root.incomingData.theme === 'blue' ? "Оплатить" : "Способы оплаты" }}</h4>
            </div>
            <div @click="$root.steps.setStep('OrderInfo')" class="pointer d-flex align-center mt-4">
                <div class="circle__img mr-4">
                    <img class="scaled-img" v-if="$root.incomingData.theme === 'purple'"  src="@/assets/img/info-purple.svg" alt="" />
                    <img v-else src="@/assets/img/info.svg" alt="" />
                    <!--                    <img src="@/assets/img/info.svg" alt="" />-->
                </div>

                <h4 class="main-title">{{ $root.incomingData.theme === 'blue' ? "Информация по заказу" : "Просроченный долг" }}</h4>
            </div>
            <div v-if="$root.incomingData.assignment !== 4 && $root.incomingData.theme !== 'blue'" @click="$modals.open('changeSheduleModal')" class="pointer d-flex align-center mt-4">
                <div class="circle__img mr-4">
                    <img src="@/assets/img/calendar.svg" alt="" />
                </div>

                <h4 class="main-title">Продлить график</h4>
            </div>
            <div v-if="$root.incomingData.assignment !== 4 && $root.incomingData.theme !== 'blue'" @click="$root.steps.setStep('ChangePaymentDate');" class="pointer d-flex align-center mt-4">
                <div class="circle__img mr-4">
                    <img src="@/assets/img/change-calendar.svg" alt="" />
                </div>

                <h4 class="main-title">Изменить дату платежа</h4>
            </div>
            <a class="main-text mt-6 single-purchase_link blue-text" href="https://paylate.ru/voprosy-i-otvety/" target="_blank">Частые ответы на вопросы</a>
        </div>
    </div>

</template>

<script>
import money from "@/helpers/moneyFormat.js";
import {dateWithoutYear} from "@/helpers/Date";

export default {
    computed: {
        anketa() {
            return this.$root.anketa;
        },

        incomingData() {
            return this.$root.incomingData;
        },

        settings() {
            return this.$root.forms.settings;
        }
    },

    mounted() {
        if(this.settings) {
            this.$root.bnplOrderId = this.settings.appId;

            console.log(this.settings)

            this.$root.incomingData.remainsPay = this.settings.debt[0].principal + this.settings.debt[0].percentage + this.settings.debt[0].fine + this.settings.debt[0].feeOverdue;
            this.$root.incomingData.isRepaidLoan = this.settings.repaidLoan;
            this.$root.incomingData.principal = this.settings.debt[0].principal;
            this.$root.incomingData.amountPurchase = this.settings.amountPurchase;
            this.$root.incomingData.overdueDebt = this.settings.debt[0].principalDebt + this.settings.debt[0].percentageDebt + this.settings.debt[0].fine + this.settings.debt[0].feeOverdue;
            this.$root.incomingData.title = this.settings.title;
            this.$root.incomingData.agreementDate = this.settings.dtClientAgreed;
            this.$root.incomingData.accruals = this.settings.accruals;
            this.$root.incomingData.schedule = this.settings.schedule;

            this.$root.incomingData.optionalServices = this.settings.optionalServices[0];

            this.$root.incomingData.percentage  = this.settings.debt[0].percentage;
            this.$root.incomingData.principalDebt  = this.settings.debt[0].principalDebt;
            this.$root.incomingData.percentageDebt = this.settings.debt[0].percentageDebt;
            this.$root.incomingData.feeOverdue = this.settings.debt[0].feeOverdue;
            this.$root.incomingData.fine = this.settings.debt[0].fine;

            // this.$root.incomingData.assignment = 4;
            this.$root.incomingData.nextPay.date = this.settings.nextPay[0].date;
            this.$root.incomingData.nextPay.amount = this.settings.nextPay[0].amount;
            this.$root.bnplOrderId = this.settings.appId;
            this.$root.incomingData.currentDate = this.settings.nextPay[0].date;

            this.$root.incomingData.assignment = this.settings.assignment;
        }
        // this.$root.incomingData.application = this.settings;
    },

    filters: {
        money,
        dateWithoutYear
    },

    data: () => ({
        info: [
            { title: "Оплатить", img: require("@/assets/img/pay.svg") },
            {
                title: "Просроченный долг",
                img: require("@/assets/img/info.svg"),
            },
            {
                title: "Продлить график",
                img: require("@/assets/img/calendar.svg"),
            },
            {
                title: "Изменить дату платежа",
                img: require("@/assets/img/change-calendar.svg"),
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
.header-icon {
    padding: 12px;
    background: #e7e7e7;
    border-radius: 8px;
    display: inline-block;
}

.colorful-block {
  &.purple {
    color: #9c6cfe;
    background: none !important;
  }

  &.blue {
    background: rgba(6, 99, 239, 0.08) !important;
    color: #22252B !important;
    font-weight: 500;
  }
}

.header-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    font-family: "SFPro", sans-serif;
    color: #848485;
    margin-left: 16px;
    margin-right: 12px;
}

.header-price {
    white-space: nowrap;
}

.circle__img {
    img {
        width: 22px;
        height: 22px;
    }
}

.single-purchase_link{
    display: block;
    margin-top: 30px !important;
    font-weight: 700;
    text-align: center;
    // color: #0A0A0D !important;
}
</style>
